/*
 Default Button Component
 */

.button {
  @apply flex items-center justify-center rounded-lg text-sm text-white border transition-all w-auto cursor-pointer focus:outline-none;

  &:disabled {
    @apply cursor-not-allowed opacity-50;
  }

  &.primary {
    @apply bg-primary-400 border-primary-400 hover:bg-primary-500 hover:border-primary-500;

    &:disabled {
      @apply bg-primary-400 border-primary-400;
    }
  }

  &.secondary {
    @apply bg-secondary-400 border-secondary-400 hover:bg-secondary-500 hover:border-secondary-500;

    &:disabled {
      @apply bg-secondary-400 border-secondary-400;
    }
  }

  &.danger {
    @apply bg-danger-400 border-danger-400 hover:bg-danger-500 hover:border-danger-500;

    &:disabled {
      @apply bg-danger-400 border-danger-400;
    }
  }

  &.warning {
    @apply bg-warning-400 border-warning-400 hover:bg-warning-500 hover:border-warning-500;

    &:disabled {
      @apply bg-warning-400 border-warning-400;
    }
  }
}

/*
 Outlined Button Component
 */

.button-outline {
  @apply flex items-center justify-center rounded border transition-all bg-transparent w-auto cursor-pointer opacity-100 focus:outline-none;

  &:disabled {
    @apply cursor-not-allowed opacity-50;
  }

  &.primary {
    @apply text-primary-400 border-primary-400 hover:bg-primary-100 hover:text-primary-500 hover:border-primary-500;

    &:disabled {
      @apply border-primary-400 bg-transparent;
    }
  }

  &.secondary {
    @apply text-secondary-400 border-secondary-400 hover:bg-secondary-100 hover:text-secondary-500 hover:border-secondary-500;

    &:disabled {
      @apply border-secondary-400 bg-transparent;
    }
  }

  &.danger {
    @apply text-danger-400 border-danger-400 hover:bg-danger-100 hover:text-danger-500 hover:border-danger-500;

    &:disabled {
      @apply border-danger-400 bg-transparent;
    }
  }

  &.warning {
    @apply text-warning-400 border-warning-400 hover:bg-warning-100 hover:text-warning-500 hover:border-warning-500;

    &:disabled {
      @apply border-warning-400 bg-transparent;
    }
  }
}

/*
 Icon Button Component
 */

.button-icon {
  @apply flex items-center justify-center rounded border transition-all text-white w-auto cursor-pointer opacity-100 focus:outline-none;

  &:disabled {
    @apply cursor-not-allowed opacity-50;
  }

  &.primary {
    @apply bg-primary-400 border-primary-400 hover:bg-primary-500 hover:border-primary-500 hover:text-white;

    &:disabled {
      @apply border-primary-400 bg-transparent;
    }
  }

  &.secondary {
    @apply bg-secondary-400 border-secondary-400 hover:bg-secondary-500 hover:border-secondary-500 hover:text-white;

    &:disabled {
      @apply border-secondary-400 bg-transparent;
    }
  }

  &.danger {
    @apply bg-danger-400 border-danger-400 hover:bg-danger-500 hover:border-danger-500 hover:text-white;

    &:disabled {
      @apply border-primary-400 bg-transparent;
    }
  }

  &.warning {
    @apply bg-warning-400 border-warning-400 hover:bg-warning-500 hover:border-warning-500 hover:text-white;

    &:disabled {
      @apply border-warning-400 bg-transparent;
    }
  }

  &.with-outline {
    @apply bg-transparent;

    &.primary {
      @apply text-primary-400 hover:bg-primary-100 hover:bg-primary-500;
    }

    &.secondary {
      @apply text-secondary-400 hover:bg-secondary-100 hover:bg-secondary-500;
    }

    &.danger {
      @apply text-danger-400 hover:bg-danger-100 hover:bg-danger-500;
    }

    &.warning {
      @apply text-warning-400 hover:bg-warning-100 hover:bg-warning-500;
    }
  }
}
